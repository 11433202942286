<template>
  <div id="summary-report" class="container-fluid">

    <h3>สรุปยอดรวม</h3>
    <CardDateFrom @selected="searchData" />

    <div class="alert alert-info">
      <b-form-group v-if="branchOptions.length" class="mb-1" label="เลือกดูทีม:" v-slot="{ ariaDescribedby }">
        <b-form-checkbox-group
          v-model="branchFilters"
          :options="branchOptions"
          :aria-describedby="ariaDescribedby"
          name="branchFilters"
        ></b-form-checkbox-group>
      </b-form-group>
    </div>

    <span class="text-primary">ข้อมูลวันที่ {{dataDateText}}</span>

    <div class="card">
      <table class="table table-sm table-bordered mb-0">
        <thead>
          <tr>
            <th rowspan="2" class="text-center">ทีม</th>
            <th colspan="4" class="text-center">ยอดเว็บ</th>
            <td rowspan="2" class="text-center" style="width: 10%; vertical-align: middle;">เงินตั้งต้น</td>
            <td rowspan="2" class="text-center" style="width: 10%; vertical-align: middle;">โอนกลับบัญชีกลาง</td>
            <td rowspan="2" class="text-center" style="width: 10%; vertical-align: middle;">เหลือ</td>
            <th colspan="2" class="text-center">สรุปได้-เสีย</th>
          </tr>
          <tr>
            <td style="width: 8%;" class="text-center">ยอดคีย์</td>
            <td style="width: 8%;" class="text-center">ยอดจ่าย</td>
            <td style="width: 8%;" class="text-center">ขาด/เกิน</td>
            <td style="width: 8%;" class="text-center">รวม</td>
            <td width="10%" class="text-center">Company</td>
            <td width="10%" class="text-center">สุทธิ</td>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in items" :key="index">
            <td class="text-center">{{item.name}}</td>
            <td class="text-right"><span v-html="numberFormat(item.amounts.sales)"></span></td>
            <td class="text-right"><span v-html="numberFormat(item.amounts.won)"></span></td>
            <td class="text-right"><span v-html="numberFormat(item.amounts.other)"></span></td>
            <td class="text-right"><span v-html="numberFormat(item.amounts.total)"></span></td>
            <td class="text-right"><span v-html="numberFormat(item.amounts.start)"></span></td>
            <td class="text-right"><span v-html="numberFormat(item.amounts.refund)"></span></td>
            <td class="text-right"><span v-html="numberFormat(item.amounts.grandTotal)"></span></td>

            <td class="text-right"><span v-html="numberFormat(item.amounts.companyWinloss)"></span></td>
            <td class="text-right"><span v-html="numberFormat(item.amounts.ownerWinloss)"></span></td>
          </tr>
          <tr v-if="!items.length">
            <td colspan="10" class="text-center">ไม่มีข้อมูล</td>
          </tr>
        </tbody>
        <tfoot v-if="items.length > 0">
          <tr>
            <th class="text-center">รวม</th>
            <th class="text-right"><span v-html="numberFormat(total.sales)"></span></th>
            <th class="text-right"><span v-html="numberFormat(total.won)"></span></th>
            <th class="text-right"><span v-html="numberFormat(total.other)"></span></th>
            <th class="text-right"><span v-html="numberFormat(total.total)"></span></th>
            <th class="text-right"><span v-html="numberFormat(total.start)"></span></th>
            <th class="text-right"><span v-html="numberFormat(total.refund)"></span></th>
            <th class="text-right"><span v-html="numberFormat(total.grandTotal)"></span></th>
            <th class="text-right"><span v-html="numberFormat(total.companyWinloss)"></span></th>
            <th class="text-right"><span v-html="numberFormat(total.ownerWinloss)"></span></th>
          </tr>
        </tfoot>
      </table>
    </div>

    <loading
      :active="isLoading"
      :can-cancel="false"
      :is-full-page="false"
      background-color="#EBEDEF"
      :height="30"
      :width="30"
      color="#007bff"
    />

  </div>
</template>
<script>
import Loading from 'vue-loading-overlay'
import 'vue-loading-overlay/dist/vue-loading.css'

import Swal from 'sweetalert2'

import moment from '@/helpers/moment'

import ReportService from '@/services/ReportService'
import BranchService from '@/services/branchService.js'

import BarChart from '@/views/components/BarChart'
import CardDateFrom from '@/views/components/CardDateFrom'

export default {
  name: 'ManagerReportSummary',
  components: {
    Loading,
    BarChart,
    CardDateFrom
  },
  data() {
    return {
      isLoading: false,
      successDataDate: null,
      dataDate: null,
      data: [],
      Branchs: [],
      branchFilters: [],

      chartSelected: ['totals'],
      chartSelectOptions: [
        { text: 'ยอดคีย์', value: 'sales' },
        { text: 'ยอดจ่าย', value: 'wons' },
        { text: 'ขาด/เกิน', value: 'others' },
        { text: 'รวม', value: 'totals' }
      ],
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false
      }

    }
  },
  computed: {
    dataDateText() {
      if(!this.successDataDate) {
        return '-'
      }

      const diff = moment(this.dataDate.end).diff(this.dataDate.start, 'd') + 1

      if(this.successDataDate.start === this.successDataDate.end) {
        return `${this.successDataDate.start}`
      }else{
        return `${this.successDataDate.start} ถึง ${this.successDataDate.end}`
      }
    },
    items() {

      return this.Branchs.map((branch)=>{

        const reports = this.data.filter((rep)=>{
          return branch._id === rep.owners.accountId
        })

        const amounts = reports.reduce((sum, report)=>{
          sum.sales += (report.amounts.sales || 0)
          sum.won += (report.amounts.won || 0)
          sum.other += (report.amounts.other || 0)
          sum.total += (report.amounts.total || 0)
          sum.start += (report.summary.start.amount || 0)
          sum.refund += (report.summary.refund.amount || 0)
          sum.grandTotal += (report.summary?.grandTotal || 0)
          sum.companyWinloss += (report.amounts?.companyWinloss || 0)
          sum.ownerWinloss += (report.amounts?.ownerWinloss || 0)
          return sum
        }, {
          sales: 0,
          won: 0,
          other: 0,
          total: 0,
          start: 0,
          refund: 0,
          grandTotal: 0,
          companyWinloss: 0,
          ownerWinloss: 0
        })

        return {
          ...branch,
          status: null,
          amounts: amounts
        }
      })
      .filter((branch)=>{
        if(this.branchFilters === null || !this.branchFilters.length)
          return true

        return this.branchFilters.includes(branch._id)
      })
    },
    total() {
      return this.items.reduce((total, d)=>{
        total.sales += d.amounts.sales
        total.won += d.amounts.won
        total.other += d.amounts.other
        total.total += d.amounts.total
        total.realBalance += d.amounts.realBalance
        total.start += d.amounts.start
        total.refund += d.amounts.refund
        total.grandTotal += d.amounts.grandTotal
        total.companyWinloss += d.amounts.companyWinloss
        total.ownerWinloss += d.amounts.ownerWinloss
        return total
      }, {
        sales: 0,
        won: 0,
        other: 0,
        total: 0,
        realBalance: 0,
        start: 0,
        refund: 0,
        grandTotal: 0,
        companyWinloss: 0,
        ownerWinloss: 0
      })
    },
    chartCollection() {

      const labels = this.items.map((item)=>{
        return this.dateFormat(item.date)
      })

      let datasets = {}
      datasets.sales = {
        label: 'ยอดคีย์',
        backgroundColor: '#c3e6cb',
        data: this.items.map((item)=>{
          return item.amounts.sales || 0
        })
      }

      datasets.wons = {
        label: 'ยอดจ่าย',
        backgroundColor: '#f5c6cb',
        data: this.items.map((item)=>{
          return item.amounts.won || 0
        })
      }

      datasets.others = {
        label: 'รายรับ/รายจ่าย',
        backgroundColor: '#bee5eb',
        data: this.items.map((item)=>{
          return item.amounts.other || 0
        })
      }

      datasets.totals = {
        label: 'รวม',
        backgroundColor: '#b8daff',
        data: this.items.map((item)=>{
          return item.amounts.total || 0
        })
      }

      return {
        labels: labels,
        datasets: this.chartSelected.map((select)=>{
          return datasets[select]
        })
      }
    },
    branchOptions() {
      return this.Branchs.map((branch)=>{
        return {
          text: branch.name,
          value: branch._id
        }
      })
    }
  },
  methods: {
    searchData(params) {
      this.dataDate = params?.date

      this.isLoading = true
      ReportService.getSummary(this.dataDate)
      .then((response)=>{
        if(response.status === 'success') {
          this.successDataDate = this.dataDate
          this.data = response.data
        }else{
          throw new Error()
        }
      })
      .catch((e)=>{
        console.log(e)
        Swal.fire({
          title: e?.name || 'ผิดพลาด!',
          text: (e?.errors?JSON.stringify(e?.errors):null || e?.error) || 'โหลดข้อมูลไม่สำเร็จ กรุณาลองใหม่อีกครั้ง',
          icon: 'error',
          confirmButtonText: 'OK'
        })
      })
      .finally(()=>{
        this.isLoading = false
      })
    },
    getBranch() {
      BranchService.gets().then((response)=>{
        if(response.status === 'success') {
          this.Branchs = response.data
        }
      })
    },
    numberFormat(number, dec=2) {
      if(!number || number==='-')
        return '-'

      number = parseFloat(number).toFixed(dec)
      const numberText = number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
      if(number > 0)
        return `<span class="text-success">${numberText}</span>`
      else if(number < 0)
        return `<span class="text-danger">${numberText}</span>`

      return numberText
    },
    dateFormat(arg) {
      return moment(arg).format("YYYY-MM-DD")
    }
  },
  filters: {
    dateFormat(arg) {
      return moment(arg).format("YYYY-MM-DD")
    }
  },
  mounted() {
    // this.searchData()
    this.getBranch()
  }
}
</script>
<style lang="scss" scoped>
#summary-report {
  position: relative;
  padding-bottom: 50px;

  .view-info {
    cursor: pointer;
  }

}
</style>
